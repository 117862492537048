import React from 'react';
import ReactPlayer from 'react-player';
import { styled } from 'styled-components';

type Props = {
    videoUrl: string
}

const ContainerVideoBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
`;

const VideoBackground = (props: Props) => {
  const handleReady = () => {
    const player = document.querySelector('video');
    if(player)player.muted = true;

  };

  return (
    <ContainerVideoBackground>
        <ReactPlayer
        url={props.videoUrl}
        playing
        loop
        muted
        width="100%"
        height="100%"
        onReady={handleReady}
        config={{
            file: {
              attributes: {
                style: {
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    objectPosition: 'center'

                },
              },
            },
          }}

      />
    </ContainerVideoBackground>
  );
};

export default VideoBackground;