import { Bounce, toast } from "react-toastify";

export const toastError = (message?: string) => {
  toast.error(message ?? "Ocurrió un error", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });
};
