import apiClient from "../api/api";
import { GetGenders } from "../interfaces/get-genders.interface";
import { GetLadaCodes } from "../interfaces/get-lada-codes.interface";

export const fetchLadaCodes = async () => {
  const response = await apiClient.get<{ data: GetLadaCodes[] }>(
    "/catalogs/get-lada-codes"
  );
  return response.data;
};

export const fetchGenders = async () => {
  const response = await apiClient.get<{ data: GetGenders[] }>(
    "/catalogs/get-genders"
  );
  return response.data;
};
