import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import RegisterHospetPage from "./pages/register-hospet.page";
import SuccessPage from "./pages/success.page";
import NotFoundPage from "./pages/not-found.page";
import RegisterHuespetPage from "./pages/register-huespet.page";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/hostpet" />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/hostpet" element={<RegisterHospetPage />} />
        <Route path="/huespet" element={<RegisterHuespetPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
