import axios, { AxiosInstance } from "axios";

const apiClient: AxiosInstance = axios.create({
  baseURL: "https://app.rumipet.com/api/", // "http://localhost:3000/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiClient;
