// src/queryClient.js
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
      staleTime: 60000, // 1 minuto
    },
  },
});

export { queryClient, QueryClientProvider };
