import styled from "styled-components";

// Styled components
export const ContainerLogoStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerPageStyled = styled.div`
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0;
  padding: 0;
`;

export const LogoStyled = styled.img`
  height: 7rem;
`;

export const Title = styled.h1`
  text-align: center;
  color: white;
  margin-bottom: 0px;
  font-family: "knewave";
  font-size: 48px;
  text-shadow: 0px 0px 5px #29aaa0;
`;

export const Subtitle = styled.h5`
  text-align: center;
  color: white;
  margin-top: 1rem;
  font-family: "Mallory";
`;

export const BottomFixCopyrightStyled = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  font-family: "Mallory";
  text-shadow: 0px 0px 3px #29aaa0;
  padding: 10px;
  font-size: 14px;
`;
