import React, { useEffect } from "react";
import videoPath from "../assets/video/rumipet-bg-video.mp4";
import logo from "../assets/image/logo_verde_rumipet.png";
import VideoBackground from "../components/video-bakcgorund";
import { Card } from "antd";
import { Button, Result } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { currentYear } from "../utils/dates";
import {
  BottomFixCopyrightStyled,
  ContainerLogoStyled,
  LogoStyled,
} from "../styles/register-hostpet.styled";
import Lottie from "lottie-react";
import successAnimation from "../assets/lotties/success.json";

const SuccessPage = () => {
  const location = useLocation();
  const { success } = location.state || {};
  const navigate = useNavigate();
  const handleClick = () => navigate("/");

  useEffect(() => {
    if (!success) {
      navigate("/");
    }
  }, [navigate, success]);

  return (
    <>
      <nav
        className="navbar fixed-top"
        style={{
          background: "transparent",
        }}
      >
        <div className="container">
          <ContainerLogoStyled>
            <LogoStyled
              className="animate__animated animate__fadeIn animate__faster"
              src={logo}
            />
          </ContainerLogoStyled>
        </div>
      </nav>
      <VideoBackground videoUrl={videoPath}></VideoBackground>
      <div
        style={{
          marginTop: "5rem",
          padding: "1.5rem",
          height: "88vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="container d-flex justify-content-center mt-4">
          <Card
            className="shadow col-12 col-xxl-6 animate__animated animate__fadeIn animated__slow"
            style={{
              background:
                "radial-gradient(circle, rgba(241,255,254,1) 0%, rgba(255,255,255,0.90) 100%)",
            }}
          >
            <Result
              icon={
                <Lottie
                  animationData={successAnimation}
                  style={{ height: "150px", width: "150px", margin: "0 auto" }}
                />
              }
              title="¡Gracias por ser parte de nosotros! Su registro se completo satisfactoriamente"
              subTitle="Recibirá instrucciones por correo electronico para validar su informacion y completar su registro"
              extra={[
                <Button
                  type="primary"
                  key="console"
                  onClick={handleClick}
                  style={{ backgroundColor: "rgb(41, 170, 160)" }}
                >
                  Aceptar
                </Button>,
              ]}
            />
          </Card>
        </div>
      </div>
      <BottomFixCopyrightStyled>
        © {currentYear}, Rumipet
      </BottomFixCopyrightStyled>
    </>
  );
};

export default SuccessPage;
