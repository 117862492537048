import React, { useEffect, useState } from "react";
import { Card } from "antd";
import videoPath from "../assets/video/rumipet-bg-video.mp4";
import logo from "../assets/image/logo_verde_rumipet.png";
import { Button, Checkbox, Form, Input, Select } from "antd";
import VideoBackground from "../components/video-bakcgorund";
import apiClient from "../api/api";
import { useQuery } from "react-query";
import { GetLadaCodes } from "../interfaces/get-lada-codes.interface";
import { GetGenders } from "../interfaces/get-genders.interface";
import "animate.css";
import { CreateUser } from "../interfaces/create-user.interface";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdInfoOutline, MdKeyboardArrowRight } from "react-icons/md";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { fetchGenders, fetchLadaCodes } from "../services/catalogs";
import { toastError } from "../utils/customToast";
import { currentYear } from "../utils/dates";
import {
  BottomFixCopyrightStyled,
  ContainerLogoStyled,
  ContainerPageStyled,
  LogoStyled,
  Subtitle,
  Title,
} from "../styles/register-hostpet.styled";
import load from "../assets/lotties/load.json";
import Lottie from "lottie-react";

const { Option } = Select;

const RegisterHospetPage = () => {
  const { data: ladaCodes, isLoading: loadingCodes } = useQuery(
    "ladaCodes",
    fetchLadaCodes
  );
  const { data: genders, isLoading: loadingGenders } = useQuery(
    "genders",
    fetchGenders
  );
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const createUser = async (data: CreateUser) => {
    setIsLoading(true);
    try {
      delete data.confirm;
      data.isHospet = true;
      const response = await apiClient.post("/auth/signup", data);
      if (response.data.success === true) {
        setIsLoading(false);
        navigate("/success");
      } else {
        setIsLoading(false);
        toastError(response.data?.response?.data?.data?.message);
      }
    } catch (error: any) {
      console.error(error.response);
      toastError(error?.response?.data?.data?.message);
      setIsLoading(false);
    }
  };

  const prefixSelector = (
    <Form.Item name="ladaCodeCatId" noStyle>
      <Select style={{ width: 70 }}>
        {ladaCodes?.data?.map((lada: GetLadaCodes) => (
          <Option key={lada.code} value={lada.id}>
            {lada.code}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const onFinish = (values: CreateUser) => createUser(values);

  const handleHuespet = () => {
    navigate("/huespet");
  };

  useEffect(() => {
    if (location.pathname === "/hostpet")
      document.title = "¿Quieres ser un hostpet? | Rumipet";
  }, [location]);

  if (loadingCodes || loadingGenders) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Lottie
          animationData={load}
          style={{ height: "400px", width: "400px" }}
        />
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <nav
        className="navbar"
        style={{
          background: "transparent",
        }}
      >
        <div className="container">
          <ContainerLogoStyled>
            <LogoStyled
              className="animate__animated animate__fadeIn animate__faster"
              src={logo}
            />
          </ContainerLogoStyled>
        </div>
      </nav>
      <VideoBackground videoUrl={videoPath}></VideoBackground>
      <ContainerPageStyled>
        <div className="d-flex align-items-center">
          <div className="container mt-4">
            <div className="row">
              <div className="col-12 col-xxl-6 d-flex flex-column justify-content-center align-items-center">
                <Title className="animate__animated animate__fadeInDown animated__fast">
                  <div>¡Cuidando, Amando y Ganando!</div>
                </Title>
                <Subtitle className=" animate__animated animate__fadeInUp">
                  Puedes ganar dinero cuidando con cariño a éstos hermosos seres
                  que nos brindan amor y alegría.
                </Subtitle>
              </div>

              <Card
                className="shadow col-12 col-xxl-6 animate__animated animate__fadeIn animate__heartBeat animated__slow"
                style={{
                  background:
                    "radial-gradient(circle, rgba(241,255,254,1) 0%, rgba(255,255,255,0.90) 100%)",
                }}
              >
                <div
                  className="p-1 pb-3 d-flex justify-content-center align-items-center"
                  style={{ fontSize: "1rem" }}
                >
                  <Tooltip id="hostpet-info" />
                  <MdInfoOutline
                    data-tooltip-id="hostpet-info"
                    data-tooltip-content="Anfitrion que cuida a las mascotas"
                  />
                  &nbsp;{" "}
                  <span style={{ fontWeight: "700" }}>
                    Registrate como Hostpet
                  </span>
                </div>
                <Form
                  form={form}
                  name="register"
                  onFinish={onFinish}
                  layout="vertical"
                  autoComplete="off"
                  initialValues={{
                    ladaCodeCatId: ladaCodes?.data.find(
                      (item: GetLadaCodes) => item.name === "México"
                    )?.id,
                  }}
                  scrollToFirstError
                >
                  <div className="row">
                    <div className="col-12">
                      <Form.Item
                        name="name"
                        label="Nombre/s"
                        hasFeedback
                        style={{ marginBottom: "0.5rem" }}
                        rules={[
                          {
                            required: true,
                            message: "¡Por favor, Ingrese su nombre o nombres!",
                            whitespace: true,
                          },
                          {
                            min: 3,
                            message:
                              "Necesita ingresar un nombre con 3 caracteres o mas",
                            whitespace: true,
                          },
                          {
                            max: 120,
                            message:
                              "Necesita ingresar un nombre con mesno de 120 caracteres",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input placeholder="Nombre/s" />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-lg-4 col-md-6">
                      <Form.Item
                        hasFeedback
                        name="lastName"
                        label="Apellido Paterno"
                        style={{ marginBottom: "0.5rem" }}
                        rules={[
                          {
                            required: true,
                            message: "¡Por favor Ingrese su apellido paterno!",
                            whitespace: true,
                          },
                          {
                            min: 3,
                            message:
                              "Necesita ingresar un apellido con 3 caracteres o mas",
                            whitespace: true,
                          },
                          {
                            max: 120,
                            message:
                              "Necesita ingresar un apellido con mesno de 120 caracteres",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-lg-4 col-md-6">
                      <Form.Item
                        hasFeedback
                        name="secondLastName"
                        label="Apellido Materno"
                        style={{ marginBottom: "0.5rem" }}
                        rules={[
                          {
                            required: true,
                            message: "¡Por favor Ingrese su apellido materno!",
                            whitespace: true,
                          },
                          {
                            min: 3,
                            message:
                              "Necesita ingresar un apellido con 3 caracteres o mas",
                            whitespace: true,
                          },
                          {
                            max: 120,
                            message:
                              "Necesita ingresar un apellido con mesno de 120 caracteres",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-lg-4 col-md-6">
                      <Form.Item
                        hasFeedback
                        name="genderCatId"
                        label="Género"
                        style={{ marginBottom: "0.5rem" }}
                        rules={[
                          {
                            required: true,
                            message: "¡Por favor, seleccione su genero!",
                          },
                        ]}
                      >
                        <Select placeholder="Seleccione su genero">
                          {genders?.data?.map((gender: GetGenders) => (
                            <Option key={gender.id} value={gender.id}>
                              {gender.gender}{" "}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-6">
                      <Form.Item
                        hasFeedback
                        name="phone"
                        label="Número teléfonico"
                        style={{ marginBottom: "0.5rem" }}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message:
                              "¡Por favor, ingrese su número de télefono!",
                          },
                          {
                            pattern: RegExp(/^\d+$/),
                            message: "Ingrese un número de télefono valido",
                          },
                          {
                            min: 6,
                            message:
                              "Su número de télefono debe tener entre 6 y 12 caracteres",
                            whitespace: true,
                          },
                          {
                            max: 12,
                            message:
                              "Su número de télefono debe tener entre 6 y 12 caracteres",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input
                          addonBefore={prefixSelector}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-6">
                      <Form.Item
                        hasFeedback
                        name="email"
                        label="E-mail"
                        style={{ marginBottom: "0.5rem" }}
                        rules={[
                          {
                            type: "email",
                            message: "Ingrese un email valido",
                          },
                          {
                            required: true,
                            message: "¡Por favor, ingrese su E-mail!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-6">
                      <Form.Item
                        name="password"
                        label="Contraseña"
                        style={{ marginBottom: "0.5rem" }}
                        rules={[
                          {
                            required: true,
                            message: "¡Por favor, ingrese una contraseña!",
                          },
                          {
                            min: 7,
                            message:
                              "La contraseña debe tener más de 6 caracteres",
                          },
                          {
                            pattern: /^[^;'"]+$/,
                            message:
                              "La contraseña contiene caracteres no permitidos.",
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password />
                      </Form.Item>
                    </div>
                    <div className="col-6">
                      <Form.Item
                        name="confirm"
                        label="Confirmar contraseña"
                        dependencies={["password"]}
                        style={{ marginBottom: "0.5rem" }}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "¡Por favor, confirme su contraseña!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "¡La confirmación no coincide con la contraseña ingresada!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </div>
                    <div className="col-12">
                      <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        style={{ marginBottom: "0.5rem" }}
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Acepte los Términos y Condiciones y Politica de Privacidad"
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Checkbox>
                          He leido y aceptado los{" "}
                          <a
                            href="https://rumipet.com?tnc=open"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Términos y Condiciones
                          </a>{" "}
                          y{" "}
                          <a
                            href="https://rumipet.com?pdp=open"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Politica de Privacidad
                          </a>
                        </Checkbox>
                      </Form.Item>
                      <Form.Item style={{ marginBottom: "0.5rem" }}>
                        <Button
                          className="col-12"
                          type="primary"
                          htmlType="submit"
                          loading={isLoading}
                          style={{
                            backgroundColor: "#29aaa0",
                            marginTop: "20px",
                            fontSize: "16px",
                            padding: "0px",
                          }}
                        >
                          Registrarse
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </Card>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Tooltip id="huespet-info" />

          <Title
            className="animate__animated animate__fadeIn animated__fast"
            style={{
              fontSize: "22px",
              cursor: "pointer",
              textShadow: "0px 0px 5px #29aaa0",
            }}
          >
            <div
              onClick={handleHuespet}
              className="d-flex justify-content-center align-items-center"
              data-tooltip-id="huespet-info"
              data-tooltip-content="Mascota que se aloja en la estancia del hostpet (anfitrion)"
            >
              Registrate como Huespet <MdKeyboardArrowRight />
            </div>
          </Title>
        </div>
        <BottomFixCopyrightStyled className="animate__animated animate__pulse">
          © {currentYear}, Rumipet
        </BottomFixCopyrightStyled>
      </ContainerPageStyled>
    </>
  );
};

export default RegisterHospetPage;
